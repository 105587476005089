import { useState } from 'react';
import BackButton from "../BackButton/BackButton";

// its so stupid!!!!
import GalleryCardList from "../../Components/GalleryCardList/GalleryCardList";
// its so stupid!!!!
import GalleryList from "../../Components/GalleryList/GalleryList";
import './GalleryDetails.scss';
import { LiaPencilAltSolid } from "react-icons/lia";
import GalleryCardNFTList from "../GalleryCardList/GalleryCardNFTList";

const GalleryDetails = ({nft}) => {



    const [viewMode, setViewMode] = useState('list');

    const handleListClick = () => {
        setViewMode('list');
    };

    const handleCreateClick = () => {
        setViewMode('cards');
    };

    return (
        <div className="gallery-details">
            {viewMode === 'cards' && (
                <>
                    <BackButton text="List" onClick={handleListClick} />
                    <h2 className="gallery-title">Gallery</h2>
                    <GalleryCardNFTList nft={nft} />
                </>
            )}
            {viewMode === 'list' && (
                <>
                    <GalleryList onCreateClick={handleCreateClick} />
                </>
            )}
        </div>
    );
};

export default GalleryDetails;