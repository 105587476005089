import { useState, useEffect } from "react";
import SearchHere from "../Search/Search";
import BackButton from "../BackButton/BackButton";
import FilterGalleryBar from "../../Components/FilterGalleryBar/FilterGalleryBar";
import GalleryCardList from "../../Components/GalleryCardList/GalleryCardList";
import { getCollectionItems, getNFTItems } from '../../services/galleryService';
import './GalleryMain.scss';

const GalleryMain = ({ onItemDoubleClick }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [initialItems, setInitialItems] = useState([]);

    useEffect(() => {
        Promise.all([getCollectionItems(), getNFTItems()])
            .then(responses => {
                const combinedItems = [
                    ...(responses[0].data || []),
                    ...(responses[1].data || [])
                ];
                console.log(combinedItems);
                setInitialItems(combinedItems);
            })
            .catch(error => console.error('Error fetching gallery data:', error));
    }, []);

    const handleSearchResults = (results) => {
        setSearchResults(results);
    };

    const handleItemDoubleClick = (obj) => {
        onItemDoubleClick(obj);
    };

    return (
        <div className="gallery-main">
            <div className="gallary-nav">
                <div>
                    <h2 className="gallary-title">All NFT</h2>
                    <BackButton text="Filter" onClick={() => console.log('Back button clicked')} />
                </div>
                <SearchHere
                    onSearch={handleSearchResults}
                    apiPath="/api/gallery"
                />
            </div>
            <div className="gallery-filtrs-cards">
                <FilterGalleryBar />
                <GalleryCardList items={searchResults.length > 0 ? searchResults : initialItems} onItemDoubleClick={handleItemDoubleClick} />
            </div>
        </div>
    );
};

export default GalleryMain;
