import React, {useState, useEffect, useRef} from 'react';
import './gallerycardnftlist.scss';
import imageGaleryDefault from '../../Assets/images/gallery_card.png'
import {LiaPencilAltSolid} from "react-icons/lia";
import {BASE_URL} from "../../services/baseURL";
import axios from "axios";

const GalleryCardNFTList = React.memo(({ nft }) => {
    const [nftList, setNftList] = useState([]);
    const [galleryName,setGalleryName] = useState('Name gallery')
    const inputRef = useRef(null);

    useEffect(() => {
        if (nft && !nftList.some(existingNft => existingNft.id === nft.id)) {
            setNftList(prevList => [...prevList, nft]);
            sendDataToServer(nft);
        }
    }, [nft]);

    useEffect(() => {
        const uniqueNftList = nftList.reduce((acc, currentNft) => {
            if (!acc.some(nft => nft.id === currentNft.id)) {
                acc.push(currentNft);
            }
            return acc;
        }, []);

        if (uniqueNftList.length !== nftList.length) {
            setNftList(uniqueNftList);
        }
    }, [nftList]);
    const handleInputChange = (e) => {
        setGalleryName(e.target.value);
    };

    const handleClickIcon = () => {
        // Перевірити, чи є ref і чи є ref.current, перш ніж встановити фокус
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };
    const sendDataToServer = () =>{
        const objToServer = {
            "title": nft.title,
            "owner": nft.owner,
            "elementURL": nft.elementURL,
            "urlSite": nft.urlSite,
            "nameGalery": galleryName
        }
        axios.post(`${BASE_URL}/api/gallery`, objToServer).then(data => {

        })
            .catch(error => {
            });

    };

    return (
        <>
            <div className='galleryBox'>
                <input
                    ref={inputRef}
                    onChange={handleInputChange}
                    value={galleryName}
                    className="gallery-title-h3"
                />
                <LiaPencilAltSolid
                    style={{cursor: 'pointer'}}
                    onClick={handleClickIcon}
                />
            </div>
            <div className="gallery-cards">
                {nftList.map((nft) => (
                    <img key={nft._id} src={nft.elementURL ? nft.elementURL : imageGaleryDefault} alt={nft.id}/>
                ))}
            </div>

        </>

    );
});

export default GalleryCardNFTList;
