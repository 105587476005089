import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./modalwindow.scss";
import { BASE_URL } from '../../services/baseURL';
import { getCategoryByTitle, updateCategory } from "../../services/categoryService";

export const ModalWindowCategory = ({ active, setActive, categoryTitle }) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [inputData, setInputData] = useState({ title: "", description: "" });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (categoryTitle.length >= 2) {
      getCategoryByTitle(categoryTitle)
          .then(data => {
            setData(data.category);
            setInputData({ title: data.category?.title, description: data.category?.description });
            setFileUrl(data.category?.photo.fileUrl);
          });
    }
  }, [categoryTitle]);

  const handleData = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
       setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('title', inputData.title);
    formData.append('description', inputData.description);
    if(file){
      formData.append('photo', file);
    }else{
      formData.append('photo', fileUrl);
    }

    try {


      const updatedData = await updateCategory(data._id, formData);
      console.log('Категорія оновлена:', updatedData);
      // Опціонально: обробка успіху, наприклад, показ повідомлення або перезавантаження сторінки/
      window.location.reload();
    } catch (error) {
      console.error('Помилка під час оновлення категорії:', error);
      // Обробка помилки (наприклад, показ повідомлення про помилку користувачеві)
    }
  };


  return (
      <div className={active ? "modal-window active" : "modal-window"} onClick={() => setActive(false)}>
        <div className={active ? "wrapper-form active" : "wrapper-form"} onClick={(e) => e.stopPropagation()}>
          <form onSubmit={handleSubmit} className='modal-window__form'>
            <h3>Edit Category</h3>
            <div className='form-block'>
              <div className='form-input__image'>
                <span>Image</span>
                {fileUrl && <img src={fileUrl} alt="Uploaded" />}
                <label className='label__image'>
                  <span>Upload Image </span>
                  <input
                      name='photo'
                      type="file"
                      className='modal-window__input-file'
                      accept='.jpeg,.jpg,.png'
                      onChange={handleChange}
                  />
                </label>
                {file && <ul className="file-list"><li>{file.name}</li></ul>}
                <span>Supported files: <span>jpeg, jpg, png</span>. Image will be resized into 250×170 px</span>
              </div>
              <div className='form-input__text'>
                <div className='form-input__name'>
                  <label>Name</label>
                  <input value={inputData.title} name="title" type="text" onChange={handleData} />
                </div>
                <div className='form-input__description'>
                  <label>Description</label>
                  <textarea value={inputData.description} name="description" onChange={handleData} />
                </div>
              </div>
            </div>
            <button className='modal-form-btn' type="submit" style={{ cursor: 'pointer' }}>Submit</button>
          </form>
        </div>
      </div>
  );
};
