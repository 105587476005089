import React, { useRef, useState } from 'react';
import './editor.scss';
import { Editor } from '@tinymce/tinymce-react';

const TXTEditor = ({
                       height = 500,
                       width = '100%',
                       initialValue = '',
                       placeholder = 'Тут можна писати',
                       onContentChange,
                       type =  'input',
                       resize = false

}) => {

    const [value, setValue] = useState(initialValue);
    const editorRef = useRef(null);

    const handleEditorChange = (content, editor) => {
        setValue(content);
        if (onContentChange) {
            onContentChange(content);
        }
    };

    return (
        <>
            <Editor
                apiKey={process.env.REACT_APP_TINY_API_KEY}
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={initialValue}
                value={value}
                onEditorChange={handleEditorChange}
                init={{
                    type: type,
                    resize: resize,
                    height: height,
                    menubar: false,
                    width: width,
                    placeholder: placeholder,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>
    );
};

export default TXTEditor;
