import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { VscSearch } from "react-icons/vsc";
import "./search.scss";

const SearchHere = ({ apiPath, onSearch }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isSearching, setIsSearching] = useState(false);

    const onSubmit = async (data) => {
        if (!data.searchQuery.trim()) {
            toast.error('Search query cannot be empty');
            return;
        }

        setIsSearching(true);
        try {
            const response = await fetch(`${apiPath}?query=${data.searchQuery}`);
            if (response.ok) {
                const data = await response.json();
                onSearch(data);
            } else {
                toast.error('Nothing found. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        } finally {
            setIsSearching(false);
        }
    };

    return (
        <div className='search-here'>
            <button type="submit" disabled={isSearching}>
                <VscSearch />
            </button>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label htmlFor="searchQuery"></label>
                    <input
                        id="searchQuery"
                        name="searchQuery"
                        {...register("searchQuery", { required: true })}
                        type="text"
                        autoComplete="off"
                        placeholder='Search here...'
                        className="search-input"
                    />
                    {errors.searchQuery && <span>This field is required</span>}
                </div>
            </form>
        </div>
    );
};

export default SearchHere;