
import React, {useState} from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import "./gallery.scss";
import GalleryMain from "../../Components/GalleryMain/GalleryMain";
import GalleryDetails from "../../Components/GalleryDetails/GalleryDetails";


const Gallery = () => {

    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemDoubleClick = (item) => {
        setSelectedItem(item)
    };


    return (
        <div className="gallary">
            <Sidebar />
            <div className="gallery__container" >
                <Navbar />
                <div style={{display: 'flex'}}>
                    <GalleryMain onItemDoubleClick={handleItemDoubleClick} />
                    <GalleryDetails nft={selectedItem} />
                </div>
                </div>
            </div>
    )
};

export default Gallery;