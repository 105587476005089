import axios from 'axios';

 // const BASE_URL = 'http://localhost:3001';
export const BASE_URL = 'https://api.tribeton.io';

export const getCollectionItems = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/collection_items`);
        return response.data;
    } catch (error) {
        console.error('Error fetching collection items:', error);
        return [];
    }
};

export const getNFTItems = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/nft-items`);
        return response.data;

    } catch (error) {
        console.error('Error fetching NFT items:', error);
        return [];
    }
};

export const createGallery = async () => {
    try {
        const response = await axios.post(`${BASE_URL}/api/galery`);
        console.log(response)
        console.log(response.data)
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
