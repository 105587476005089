import {useEffect, useState} from 'react';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { LiaPencilAltSolid } from "react-icons/lia";
import './GalleryList.scss';
import axios from "axios";
import {BASE_URL} from "../../services/baseURL";

const GalleryList = ({ onCreateClick }) => {
    const [galleris,setGalleris] = useState([])

    // const [galleries, setGalleries] = useState([
    //     { id: 1, name: 'Gallary name', isEditing: false, editingName: '' },
    // ]);
    //
    // const editGallery = (id) => {
    //     setGalleries(galleries.map(gallery =>
    //         gallery.id === id ? { ...gallery, isEditing: true, editingName: gallery.name } : gallery
    //     ));
    // };
    //
    // const saveGallery = (id) => {
    //     setGalleries(galleries.map(gallery =>
    //         gallery.id === id ? { ...gallery, isEditing: false, name: gallery.editingName } : gallery
    //     ));
    // };

    useEffect(() => {

            axios.get(`${BASE_URL}/api/galleries` ).then(data => {
                setGalleris(data.data.galeries)
            })
                .catch(error => {
                });

    }, []);
    console.log(galleris)

    return (
        <div>
            <button type="button" className="create-button" onClick={onCreateClick}>Create new</button>
            <ul className='gallery-list'>
                {galleris.map(gallery => (
                    <li key={gallery._id} className="gallary-box-edit">
                            <h3 className='list-title'>{gallery.nameGalery ? gallery.nameGalery : 'undefined'}</h3>
                        <div className='box-buttons'>
                            <button type="button" className='edit-button'><LiaPencilAltSolid /> Edit</button>
                            <button type="button" className='more-button'><PiDotsThreeOutlineVerticalFill /> More</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GalleryList;