import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import "../../../Components/NFTList/nftlist.scss";
import NFTItem from "../../../Components/NFTItem/NFTItem";
import { BASE_URL } from "../../../services/baseURL";
import ElementMoreInfo from "./ElementMoreInfo";
import { fetchAllItems } from "../../../utils/fetchAllItems";
import { Spa } from "@mui/icons-material";

const ActiveItems = ({ type }) => {
  const [nftList, setNftList] = useState([]);
  const [item, setItem] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [isNftItemClicked, setIsNftItemClicked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let nftData = [];
        let disabledNftData = [];

        switch (type) {
          case "collection_items":
            [nftData, disabledNftData] = await Promise.all([
              fetchAllItems(`${BASE_URL}/api/collection/collection_items`),
              fetchAllItems(`${BASE_URL}/api/disable/collection_items`),
            ]);
            break;
          case "collection":
            [nftData, disabledNftData] = await Promise.all([
              fetchAllItems(`${BASE_URL}/api/collections`),
              fetchAllItems(`${BASE_URL}/api/disable/collections`),
            ]);
            break;
          case "nfts":
            [nftData, disabledNftData] = await Promise.all([
              fetchAllItems(`${BASE_URL}/api/nft-items`),
              fetchAllItems(`${BASE_URL}/api/disable/nfts`),
            ]);
            break;
          default:
            return;
        }

        const combinedData = [...nftData, ...disabledNftData];
        setNftList(combinedData);
      } catch (error) {
        setNftList(false);
      }
    };

    fetchData();
  }, [type]);

  const handleNftItemClick = useCallback((item, type) => {
    setItem(item);
    setItemType(type);
    setIsNftItemClicked(true);
  }, []);

  const handleNftMoreInfoFalse = useCallback(() => {
    setIsNftItemClicked(false);
  }, []);

  const handleReject = useCallback((itemId) => {
    setNftList((prevNftList) =>
      prevNftList.filter((item) => item._id !== itemId)
    );
    setIsNftItemClicked(false);
  }, []);

  return (
    <div className="nftList">
      {!isNftItemClicked && (
        <div className="nftList__container">
          <div className="nftList__h1">
            <h1>
              {type === "collection_items"
                ? "Collection-items"
                : type === "nfts"
                ? "NFT"
                : "Collection"}
            </h1>
          </div>
          <div className="nftList__items">
            {nftList ? (
              nftList.map((item, index) => (
                <div className="manage-item-block">
                  {item.status !== "published" && <div className="status-card">No published</div>}
                  <NFTItem
                    type={type}
                    key={index}
                    nft={item}
                    onClick={() => handleNftItemClick(item, type)}
                  />
                </div>
              ))
            ) : (
              <div>Something went wrong (try to restart the page)</div>
            )}
          </div>
        </div>
      )}
      {isNftItemClicked && item && (
        <ElementMoreInfo
          type={itemType}
          onClick={handleNftMoreInfoFalse}
          onReject={handleReject}
          item={item}
        />
      )}
    </div>
  );
};

export default React.memo(ActiveItems);
