import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowBackIosNew } from "react-icons/md";
import './BackButton.scss';

const BackButton = ({ text, onClick }) => {
    return (
        <button className="back-button" onClick={onClick}>
            <MdArrowBackIosNew />
            {text}
        </button>
    );
};

BackButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default BackButton;
