import "./widgets.scss"
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import axios from "axios";

const WidgetsUsers = ({type}) => {
  let data;

  switch(type){
    case "total":
      data ={
        title:"Total Users",
        number:98,
        icon: (
          <GroupOutlinedIcon className="icon"/>
        ),
        background : { backgroundColor: '#4634FF'}
      };
      break;
      case "active":
      data ={
        title:"Active Users",
        number:1,
        icon: (
          <VerifiedUserOutlinedIcon className="icon"/>
        ),
        background : { backgroundColor: '#28C76F'},
      };
      break;
      case "email":
      data ={
        title:"Email Unverified Users",
        number:97,
        icon: (
          <EmailOutlinedIcon className="icon"/>
        ),
        background : { backgroundColor: '#EA5455'},
      };
      break;
      case "mobile":
      data ={
        title:"Mobile Unverified Users",
        number:96,
        icon: (
          <SpeakerNotesOffOutlinedIcon className="icon"/>
        ),
        background : { backgroundColor: '#F44336'},
      };
      break;
  }
 


  return (
    <div style={data.background} className="widget">
        <div className="left">
            {data.icon}
        </div>
        <div className="right">
            <span className="title">{data.title}</span>
            <span className="counter">{data.number}</span>
        </div>
    </div>
  )
}

export default WidgetsUsers