import React from 'react'
import ItemWait from '../../../Components/ManageNFT/ManageWait/ItemWait'
import Navbar from '../../../Components/Navbar/Navbar'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import "./manageWaite.scss"
import NftWait from '../../../Components/ManageNFT/ManageWait/NftWait'
import CollectionWait from '../../../Components/ManageNFT/ManageWait/CollectionWait'
function ManageWait() {
  return (
    <div className='manage-waite'>
        <Sidebar/>
        
        <div className='manage-waite__container'>
        <Navbar/>
            <div className='manage-wait-block'>
                <ItemWait/>
                <NftWait/>
                <CollectionWait/>
            </div>
            
        </div>
        
    </div>
  )
}

export default ManageWait