import axios from 'axios';
import {BASE_URL} from "./baseURL";


export const getCategoryByTitle = async (title) => {
    const response = await axios.get(`${BASE_URL}/api/category/${title}`,);
    return response.data;
};export const updateCategory = async (id,body) => {
    const response = await axios.put(`${BASE_URL}/api/category/update/${id}`,body);
    return response.data;
};

// export const verifyTwoFactor = async (username, twoFactorCode) => {
//     const response = await axios.post(`${BASE_URL}/api/verify-2fa`, { username, token: twoFactorCode });
//     return response.data;
// };
