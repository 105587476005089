import React from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import NFTList from "../../Components/NFTList/NFTList";
import Collection from "../../Components/CollectionList/Collection";
import './moderation.scss'

const Moderation = () => {


    return (
        <div className="gallary">
            <Sidebar />
            <div className="gallery__container">
                <Navbar />
                <div className="moderation">
                    <div className="moderation__container">
                        <NFTList/>
                        <Collection/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Moderation;