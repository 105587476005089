import React, {useEffect, useState} from 'react';
import './2faform.scss'
import {setupTwoFactor, verifyTwoFactor} from "../../services/authService";
import {toast} from "react-toastify";
const TwoFaForm = ({username,password}) => {
    const [twoFactorCode, setTwoFactorCode] = useState("");
    const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState(null);


    const handleTwoFactorSetup = () => {
        setupTwoFactor(username, password)
            .then(data => {
                console.log(data)
                // setQrCodeUrl(data.otpauthUrl);  // Перевірка отримання otpauthUrl
                // setShowTwoFactorInput(true);
            })
            .catch(error => {
                console.error('Two-factor setup failed', error);
                toast.error('Two-factor setup failed');
            });
    };



    const handleTwoFactorSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await verifyTwoFactor(username, twoFactorCode);
            if (result.success) {
                // navigate("/home");
                toast.success('Successfully logged in');
            } else {
                toast.error('Invalid two-factor code');
            }
        } catch (error) {
            console.error("Two-factor authentication failed", error);
            toast.error('Two-factor authentication failed');
        }
    };

    useEffect(() => {
        handleTwoFactorSetup()
    }, []);

    return (
        <div>
            <div className="form-title">
                <h2>Welcome to <span>tribe ton</span></h2>
                <p>Admin Login to TRIBE TON</p>
            </div>
            <div className="form-input">
                <div className="input-box">
                    <label htmlFor="">Verify 2fa</label>
                    {/*<input type="text" required value={username}*/}
                    {/*       onChange={(e) => setUsername(e.target.value)}/>*/}
                </div>
                <div className="input-box">
                    <label htmlFor="">Setup 2fa</label>
                    {/*<input type="password" required value={password}*/}
                    {/*       onChange={(e) => setPassword(e.target.value)}/>*/}
                </div>
                <div className="remember-forgot">
                    <input id="check" name="check" type="checkbox"/>
                    <label htmlFor="check">Remember me</label>
                </div>
                <button type="submit" className="btn">Login</button>
            </div>
        </div>
    );
};

export default TwoFaForm;