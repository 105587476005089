import "./navbar.scss"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import avatar from "../../Assets/images/avatar.png"

const Navbar = () => {
  return (
    <div className="navBar">
        <div className="wrapper_navbar">
            <div className="navbar__left">
                <div className="search">
                    <SearchOutlinedIcon/>
                    <input type="text" placeholder="Search"/>
                </div>
            </div>
            <div className="navbar__right">
                <div className="navbar__buttons">
                    <div className="navbar__btn btn__gren">
                        <button>Marketplace Balance</button>
                    </div>
                    <div className="navbar__btn btn__red">
                        <button>Withdraw All</button>
                    </div>
                </div>
                <div className="item">
                    <NotificationsNoneOutlinedIcon className="icon"/>
                </div>
                <div className="item">
                    <img src={avatar} alt="avatar" />
                    <div className="avatar_email">
                        tribetonadmin
                    </div>
                </div>
            </div>         
        </div>
    </div>

  )
}

export default Navbar