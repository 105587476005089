import React, { useState, useEffect } from 'react';
import './modalimage.scss'; // Создайте CSS файл для стилей
import { Livepeer } from "livepeer";
import * as Player from "@livepeer/react/player";
import { getSrc } from "@livepeer/react/external";
import axios from "axios";

import BASE_URL from '../../services/baseURL';

const livepeer = new Livepeer({
    apiKey: "524787ae-6a69-4994-bfcd-d0b737c6517b",
});

const getPlaybackSource = async (playbackId) => {
    const playbackInfo = await livepeer.playback.get(playbackId);
    const src = getSrc(playbackInfo.playbackInfo);
    return src;
};

const ModalImage = ({ imageSrc, altText , nft,imgClass}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [videoSrc, setVideoSrc] = useState('');
    const [collections, setCollections] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const isVideo = (url) => {
        const regex = /https:\/\/lvpr\.tv\/\?v=([\w\d]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const fetchCollections = async (page = 1, accumulatedCollections = []) => {
        try {
            const response = await axios.get(`https://api.tribeton.io/api/collections?limit=10&sort=latest&page=${page}`);
            const newCollections = response.data.data;
            const allCollections = [...accumulatedCollections, ...newCollections];

            if (newCollections.length === 10) {
                // Continue fetching the next page
                fetchCollections(page + 1, allCollections);
            } else {
                // All collections have been fetched
                setCollections(allCollections);
                setHasMore(false);
            }
        } catch (error) {
            console.error("There was an error fetching the collections!", error);
            setHasMore(false);
        }
    };

    useEffect(() => {
        setCollections([]);
        setHasMore(true);
        fetchCollections();
    }, []);

    const isAudioFile = (article) => {
        const relatedCollection = collections.find(
            (col) => col._id === article?.collection_id
        );
        return (
            relatedCollection &&
            relatedCollection.category_id === "667ee42be03e65cd12d5b8c7"
        );
      };
    const getCollectionAvatar = (article) => {
        const relatedCollection = collections.find(
            (col) => col._id === article?.collection_id
        );
        return relatedCollection ? relatedCollection.avatar?.fileUrl : null;
    };

    useEffect(() => {
        const loadVideoSrc = async () => {
            const playbackId = isVideo(imageSrc);
            if (playbackId) {
                const src = await getPlaybackSource(playbackId);
                setVideoSrc(src);
            }
            setIsLoading(false);
        };

        loadVideoSrc();
    }, [imageSrc]);

    const collectionAvatar = getCollectionAvatar(nft);
    return (
        <div>
            {isLoading && <p>Loading...</p>}
            {videoSrc ? (
                <div className="divdidvi">
                    <Player.Root
                        src={videoSrc}
                        style={{ position: "relative", height: "265px" }}
                    >
                        <Player.Container style={{ position: "relative", height: "265px" }}>
                            <Player.Video
                                style={{
                                    width: "100%",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                    position: "relative",
                                }}
                            />
                        </Player.Container>
                    </Player.Root>
                </div>
            ) : isAudioFile(nft) ? (
                <div className="card_audio" style={{ position: "relative"}}>
                    <img src={collectionAvatar} alt="audio" style={{ position: "relative"}}/>
                    <audio controls style={{ position:"absolute", left:"0",bottom:'0'}}>
                        <source src={imageSrc} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            ) : (
                <img
                    src={imageSrc}
                    alt={altText}
                    onClick={handleOpenModal}
                    onLoad={handleImageLoad}
                    className={`${imgClass}`}
                    style={{ cursor: 'pointer', width: '200px', display: isLoading ? 'none' : 'block' }}
                />
            )}

            {isOpen && (
                <div className="modal" onClick={handleCloseModal}>
                    <span className="close">&times;</span>
                    <img className='modal-content' src={imageSrc} alt={altText}/>
                </div>
            )}
        </div>
    );
};

export default ModalImage;
