import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./datatable.scss";
import { BASE_URL } from "../../services/baseURL";
import axios from "axios";
import {ModalWindow} from "../ModalWindow/ModalWindow";
import {ModalWindowCategory} from "../ModalWindow/ModalWindowCategory";

export default function Datatable() {
  const [modalActive, setModalActie] = useState(false);
  const [modalActiveCategory, setModalActiveCategory] = useState(false);
  const [articles, setArticles] = useState([]);
  const [title, setTitle] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/categories`).then((data) => {
      setArticles(data.data.category);
    });
  }, []);

  return (
    <div className="table__container">
      <div className="table__title">
        <h3>All Categories</h3>
        <div className="table__title-buttons">
          <div className="search">
            <input type="text" placeholder="Search" />
            <span>
              <SearchOutlinedIcon />
            </span>
          </div>
          <button
            className="open-modal-form"
            onClick={() => setModalActie(true)}
          >
            + Add New
          </button>
        </div>
      </div>
      <TableContainer sx={{ margin: "0 auto", border: 0 }} component={Paper}>
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableHead
            sx={{
              backgroundColor: "#4634ff",
              fontFamily: '"Poppins", sans-serif;',
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                sx={{
                  border: "none",
                  color: "white",
                  fontFamily: '"Poppins", sans-serif;',
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  width: "38%",
                  color: "white",
                  fontFamily: '"Poppins", sans-serif;',
                  fontWeight: "600",
                  fontSize: "12px",
                }}
                align="center"
              >
                Short Description
              </TableCell>
              <TableCell
                sx={{
                  width: "20%",
                  color: "white",
                  fontFamily: '"Poppins", sans-serif;',
                  fontWeight: "600",
                  fontSize: "12px",
                }}
                align="center"
              >
                Featured
              </TableCell>
              <TableCell
                sx={{
                  width: "20%",
                  color: "white",
                  fontFamily: '"Poppins", sans-serif;',
                  fontWeight: "600",
                  fontSize: "12px",
                }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontFamily: '"Poppins", sans-serif;',
                  fontWeight: "600",
                  fontSize: "12px",
                }}
                align="right"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((article) => {
              return (
                <TableRow
                  key={article._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#5b6e88",
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    component="th"
                    scope="row"
                  >
                    {article.title}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#5b6e88",
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "500",
                      fontSize: "13px",
                      padding: "15px",
                    }}
                    align="center"
                  >
                    {article.description}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    <div className="featured">
                      {article.featured ? (
                        <div className="featured__true">Yes</div>
                      ) : (
                        <div className="featured__false">No</div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    <div className="status">
                      {article.status ? (
                        <div className="status__true">Enabled</div>
                      ) : (
                        <div className="status__false">Disabled</div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                  >
                    <div className="table_buttons">
                      <div className="table_btn" style={{cursor: 'pointer'}} onClick={() => {
                          setModalActiveCategory(true);
                          setTitle(article.title);
                      }}>Edit</div>
                      <div className="table_btn_more">More</div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalWindow active={modalActive} setActive={setModalActie} />
      <ModalWindowCategory active={modalActiveCategory} setActive={setModalActiveCategory} categoryTitle={title} />
    </div>
  );
}
