import React from 'react';
import imageGaleryDefault from '../../Assets/images/gallery_card.png';
import './GalleryCardList.scss';
import noImage from '../../Assets/images/no-image.jpg'

const GalleryCardList = ({ items, onItemDoubleClick }) => {
    const handleDoubleClick = (obj) => {
        onItemDoubleClick(obj);
    };

    return (
        <ul className="gallery-cards">
            {items.map(item => (
                <li
                    key={item._id}
                    className="gallery-item"
                    onDoubleClick={() => handleDoubleClick({
                        'title': item.name,
                        'owner': item.user_address,
                        'elementURL':item.image || item.image_url ,
                        'urlSite': '/',
                        'nameGalery': item.name,
                        'id': item._id
                    })}
                >
                    <img
                        src={item.image_url || item.image || imageGaleryDefault}
                        alt={item.title}
                        onError={(e) => { e.target.src = noImage; }}
                    />
                    {/*<h3>{item.title}</h3>*/}
                </li>
            ))}
        </ul>
    );
};

export default GalleryCardList;
