import axios from 'axios';
import {BASE_URL} from "./baseURL";


export const getBlogs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/blogs`);
        return response.data;
    } catch (error) {
        console.error('Error fetching collection items:', error);
        return [];
    }
};


export const createBlogPost = async (data,headers) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/blog`, data,headers);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}


export const getDisableCollectionItems = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/disable/collection_items`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const getCollectionItems = async (page = 1) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/collection_items?page=${page}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
export const getCollectionItemById = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/collection_items/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
export const DisableCollectionItemById = async (id,body) => {
    try {
        const response = await axios.patch(`${BASE_URL}/api/visibility/collection_item/${id}`,body);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
export const DisableCollectionById = async (id,body) => {
    try {
        const response = await axios.patch(`${BASE_URL}/api/visibility/collection/${id}`,body);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const DisableNftItemById = async (id,body) => {
    try {
        const response = await axios.patch(`${BASE_URL}/api/visibility/nft/${id}`,body);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}


export const getModerationCollectionItem = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/moderation/collection-item/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
export const getModerationNFT = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/moderation/nft/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const approveNFTWithoutCollection = async (nftId,status) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/nft/${nftId}`, status);
        return response.data;
    } catch (error) {
        console.error("Error approving NFT:", error);
    }
};

export const approveNFTWithCollection = async (nftId,status) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/collection-item/${nftId}`, status);
        return response.data;
    } catch (error) {
        console.error("Error approving NFT with collection:", error);
    }
};

export const rejectNFTWithoutCollection = async (nftId,status) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/moderation/reject/nft/${nftId}`, status);
        return response.data;
    } catch (error) {
        console.error("Error approving NFT:", error);
    }
};

export const rejectNFTWithCollection = async (nftId,status) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/moderation/reject/collection-item/${nftId}`, status);
        return response.data;
    } catch (error) {
        console.error("Error approving NFT with collection:", error);
    }
};

export const getModerationCollection = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/moderation/collection/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const approveCollectionReq = async (collectionId,status) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/collection/${collectionId}`, status);
        return response.data;
    } catch (error) {
        console.error("Error approving NFT with collection:", error);
    }
};

export const RejectCollectionReq = async (collectionId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/moderation/reject/collection/${collectionId}?status=reject`)
        return response.data;
    } catch (error) {
        console.error("Error approving NFT with collection:", error);
    }
};

export const ChangeCollectionPropertyById = async (collectionId,data) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/collection/${collectionId}`,data)
        return response.data;
    } catch (error) {
        console.error("Error approving NFT with collection:", error);
    }
};

export const deleteBlogById = async (blogId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/delete/blog/${blogId}`)
        return response.data;
    } catch (error) {
        console.error("Error approving NFT with collection:", error);
    }
};