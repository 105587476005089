import "./widgets.scss"
import "./widgetsTotal.scss"

import Ov1 from "../../Assets/images/Overlay.png"
import Ov2 from "../../Assets/images/Overlay1.png"
import Ov3 from "../../Assets/images/Overlay3.png"
import Ov4 from "../../Assets/images/Overlay4.png"
import axios from "axios";

const Widgets = ({type}) => {
  let data;

  switch(type){
    case "total":
      data ={
        title:"Total Deposited",
        icon: Ov1,
        number:0,
        background : { backgroundColor: '#4634FF'},
        linkColor : {borderColor:'rgba(255, 255, 255, 0.15)',backgroundColor:'rgba(255, 255, 255, 0.1'}
      };
      break;
      case "active":
      data ={
        title:"Total Deposits",
        icon: Ov2,
        number:0,
        background : { backgroundColor: '#127681'},
        linkColor : {borderColor:'rgba(255, 255, 255, 0.15)',backgroundColor:'rgba(255, 255, 255, 0.1)'}
      };
      break;
      case "email":
      data ={
        title:"Total Withdrawn",
        icon: Ov3,
        number:0,
        background : { backgroundColor: '#FF5200'},
        linkColor : {borderColor:'rrgba(255, 255, 255, 0.15)',backgroundColor:'rgba(255, 255, 255, 0.1)'},
        
      };
      break;
      case "mobile":
      data ={
        title:"Total Withdraws",
        icon: Ov4,
        number:0,
        background : { backgroundColor: '#342EAD'},
        linkColor : {borderColor:'rgba(255, 255, 255, 0.15)',backgroundColor:'rgba(255, 255, 255, 0.1)'},
      };
      break;
  }
 


  return (
    <div style={data.background} className="widget ">
        <div className="left left__total">
            <img src={data.icon} alt="" />
        </div>
        <div className="center center__total">
            <span className="counter">{data.number}</span>
            <span className="title">{data.title}</span>
        </div>
        <div className="right right__total">
          <div style={data.linkColor} className="right__button right__button__center">
            <a href="#">View All</a>
          </div>
        </div>
    </div>
  )
}

export default Widgets