import Sidebar from "../../Components/Sidebar/Sidebar"
import Navbar from "../../Components/Navbar/Navbar"
import "./category.scss"
import BasicTable from "../../Components/Datateble/Datatable"
import Datatable from "../../Components/Datateble/Datatable"

function Category() {
  return (
    <div className="category">
        <Sidebar/>
        <div className="category__container">
            <Navbar/>
            <Datatable/>
        </div>
    </div>
  )
}

export default Category