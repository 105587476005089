import "./charts.scss";
import axios from "axios";
import { useState,useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "January", Total: 1200 },
  { name: "February", Total: 2100 },
  { name: "March", Total: 800 },
  { name: "April", Total: 1600 },
  { name: "May", Total: 900 },
  { name: "June", Total: 1700 },
];

const Charts = ({ aspect, title }) => {
  // const [articles , setArticles] = useState([])

  //   useEffect(()=>{
  //       axios.get("https://robin-back.yota-x.dev/api/nft-item").then(data => {
  //           setArticles((console.log(data.data)));
            
  //       })
  //   },[])
  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={2 / 2}>
        <AreaChart
          width={530}
          height={2 / 2}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#008FFB" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#008FFB" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="5 5" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#008FFB"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
        </ResponsiveContainer>
    </div>
  );
};

export default Charts;