import React, { useState, useEffect } from 'react';
import ModalImage from "../../../Components/ModalImage/ModalImage";
import {
    DisableCollectionById,
    DisableCollectionItemById, DisableNftItemById,
} from "../../../services/serverRequest";

const ElementMoreInfo = ({type,onClick, onReject,item}) => {
    const [isEnabled, setIsEnabled] = useState(item.isEnabled);

    useEffect(() => {
        setIsEnabled(item.isEnabled);
    }, [item]);

    const handleBackClick = () => {
        onClick();
    };
    const disableOrEnableElement = (itemId) =>{
        const body = {
            "isEnabled": !isEnabled
        };

        let disableFunction;

        if(type === 'collection_items'){
            disableFunction = DisableCollectionItemById;
        }
        else if(type === 'nfts'){
            disableFunction = DisableNftItemById;
        }
        else if(type === 'collection'){
            disableFunction = DisableCollectionById;
        }else{
            return;
        }

        disableFunction(itemId, body).then(response => {
            setIsEnabled(!isEnabled);
            console.log(response);
        });

    }
    return (
        <>
                <div className="nftInfo">
                    <div className="nftInfo__container">
                        <button className="nftInfo__container_back" onClick={handleBackClick}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                height="20px"
                                version="1.1"
                                viewBox="0 0 512 512"
                                width="20px"
                            >
                                <polygon
                                    points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
                                    style={{fill: "white"}}
                                />
                            </svg>
                            <p>Back</p>
                        </button>
                        <h2>{item.name}</h2>
                        <h4>Creator: {item.user_address}</h4>
                        <div className="nftInfo__box">
                            {/*<img src={nft.image?.fileUrl} alt="" />*/}
                            {type === 'collection' ? (
                                <>
                                    <ModalImage nft={item} imageSrc={item.avatar?.fileUrl} altText="Avatar Image" />
                                    <ModalImage nft={item} imageSrc={item.banner?.fileUrl} altText="Banner Image" />
                                </>
                            ) : (
                                <ModalImage nft={item} imageSrc={item.image?.fileUrl} altText="Image" />
                            )}


                            <div className="nftInfo__box_right">
                                <div className="nftInfo__box_right_top">
                                    <h4 className="nftInfo__box_right_top_h4">Contract</h4>
                                    <div className="nftInfo__box_right_top_content">
                                        <p className="nftInfo__box_right_top_content_p">
                                            {/*Category: {item.category_id ? item.category_id : "-"}*/}
                                        </p>
                                        <p className="nftInfo__box_right_top_content_p">
                                            Collection: {item.collection_id ? item.collection_id : "-"}
                                        </p>
                                        <p
                                            className="nftInfo__box_right_top_content_p"
                                            style={{wordWrap: "break-word"}}
                                        >
                                            Description: {item.description ? item.description : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="nftInfo__box_right_bottom">
                                    <p className="nftInfo__box_right_bottom_h4">Social networks:</p>
                                    <div className="nftInfo__box_right_top_content">
                                        <p className="nftInfo__box_right_top_content_p">Telegram: <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={`https://t.me/${item.telegram}`}>{item.telegram ? item.telegram : "-"}</a>
                                        </p>
                                        <p className="nftInfo__box_right_top_content_p">YouTube: <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={`https://www.youtube.com/${item.youtube}`}>{item.youtube ? item.youtube : "-"}</a>
                                        </p>
                                        <p className="nftInfo__box_right_top_content_p">Discord: <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={`https://discord.gg/@${item.discord}`}>{item.discord ? item.discord : "-"}</a>
                                        </p>
                                        <p className="nftInfo__box_right_top_content_p">X: <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={`https://x.com/#{nft.x}`}>{item.x ? item.x : "-"}</a></p>
                                        <p className="nftInfo__box_right_top_content_p">Token ID: -</p>
                                        <p className="nftInfo__box_right_top_content_p">
                                            Transaction Hash:
                                        </p>
                                        <p className="nftInfo__box_right_top_content_p">-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nftInfo__btns">
                        <button
                            onClick={() => {
                                disableOrEnableElement(item._id);
                            }}
                            className={isEnabled ? "nftInfo__redBtn" : "nftInfo__greenBtn"}
                        >
                            {isEnabled ? 'Виключити' : 'Включити'}
                        </button>
                        </div>
                    </div>
                </div>
        </>

    );
};

export default ElementMoreInfo;