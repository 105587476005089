import React, { useState } from "react";
import "./FilterGalleryBar.scss";

const FilterGalleryBar = () => {
    const [filters, setFilters] = useState({
        price: { min: "", max: "" },
        artistLvl: { min: "", max: "" }
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name.split('-')[0]]: {
                ...prevFilters[name.split('-')[0]],
                [name.split('-')[1]]: value
            }
        }));
    };

    return (
        <div className="filters">
            <h2>Price</h2>
            <div className="filter-range">
                <div className="input-box">
                    <div className="input-group">
                        <input
                            id="min-value"
                            type="text"
                            name="price-min"
                            placeholder="min"
                            value={filters.price.min}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            id="max-value"
                            type="text"
                            name="price-max"
                            placeholder="max"
                            value={filters.price.max}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>

            <div className="filter-category">
                <h2>Category</h2>
                <ul>
                    <li className="item-list">
                        <button type="button" className="button-option">Art</button>
                    </li>
                    <li className="item-list">
                        <button type="button" className="button-option">Video</button>
                    </li>
                    <li className="item-list">
                        <button type="button" className="button-option">Sketches</button>
                    </li>
                </ul>
            </div>

            <div className="filter-range">
                <h2>Artist lvl</h2>
                <div className="input-box">
                    <div className="input-group">
                        <input
                            id="min-value-2"
                            type="text"
                            name="artistLvl-min"
                            placeholder="min"
                            value={filters.artistLvl.min}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            id="max-value-2"
                            type="text"
                            name="artistLvl-max"
                            placeholder="max"
                            value={filters.artistLvl.max}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterGalleryBar;