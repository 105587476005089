import React from 'react';
import './levelchange.scss';

const LevelChange = ({ lvl, setLevel }) => {
    const handleChange = (event) => {
        setLevel(event.target.value);
    };

    return (
        <div className="level">
            <div className="search">
                <input type="number" value={lvl} onChange={handleChange} />
            </div>
        </div>
    );
};

export default LevelChange;
