import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './globalstyles.scss'
import LoginForm from './Pages/LoginForm/LoginForm';
import Home from './Pages/Home/Home';
import Category from './Pages/Cartegories/Category';
import Moderation from './Pages/Moderation/Moderation';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Gallery from './Pages/Gallery/Gallery';
import Blog from "./Pages/Blog/Blog";
import './utils/routerCustom'
import './utils/preventDevTools'
import ProtectedRoute from "./utils/ProtectedRoute";
import PublicRoute from "./utils/PublicRoute";
import ManageWait from './Pages/Manage/ManageWait/ManageWait';
import ManageActive from './Pages/Manage/ManageActive/ManageActive';
import ManageAllNft from './Pages/Manage/ManageAllNft/ManageActive';
function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Navigate to="/login" replace />} />
                    <Route path='login' element={<PublicRoute element={<LoginForm />} />} />
                    <Route path='home' element={<ProtectedRoute element={<Home />} />} />
                    <Route path='category' element={<ProtectedRoute element={<Category />} />} />
                    <Route path='moderation' element={<ProtectedRoute element={<Moderation />} />} />
                    <Route path='gallery' element={<ProtectedRoute element={<Gallery />} />} />
                    <Route path='blog' element={<ProtectedRoute element={<Blog />} />} />
                    <Route path='*' element={<Navigate to="/home" replace />} />
                    <Route path='manage-nft/active-items' element={<ProtectedRoute element={<ManageActive />} />}/>
                    <Route path='manage-nft/wait-to-publish' element={<ProtectedRoute element={<ManageWait />} />}/>
                    <Route path='manage-nft/all-nft' element={<ProtectedRoute element={<ManageAllNft />} />}/>
                </Routes>
                <ToastContainer />
            </BrowserRouter>
        </div>
    );
}

export default App;
