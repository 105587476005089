
import "./widgets.scss"
import "./widgetsTotal.scss"

import BG1 from "../../Assets/images/Background.png"
import BG2 from "../../Assets/images/Background2.png"
import BG3 from "../../Assets/images/Background3.png"
import BG4 from "../../Assets/images/Background4.png"
import axios from "axios";

const WidgetsTotal = ({type}) => {
  let data;

  switch(type){
    case "total":
      data ={
        title:"Total Deposited",
        number:0,
        icon: BG1,
        color : { color: 'black'},
        linkColor : {borderColor:'#4634ff',color: '#4634ff'}
      };
      break;
      case "active":
      data ={
        title:"Total Deposits",
        number:0,
        icon: BG2,
        color : { color: 'black'},
        linkColor : {borderColor:'#28c76f',color: '#28c76f'}
      };
      break;
      case "email":
      data ={
        title:"Total Withdrawn",
        number:0,
        icon:BG3,
        color : { color: 'black'},
        linkColor : {borderColor:'#4634ff',color: '#4634ff'},
        
      };
      break;
      case "mobile":
      data ={
        title:"Total Withdraws",
        number:0,
        icon:BG4,
        color : { color: 'black'},
        linkColor : {borderColor:'#28c76f',color: '#28c76f'},
      };
      break;
  }
 


  return (
    <div style={data.color} className="widget ">
        <div className="left left__total">
            <img src={data.icon} alt="" />
        </div>
        <div className="center center__total">
            <span className="counter">{data.number}</span>
            <span className="title">{data.title}</span>
        </div>
        <div className="right right__total">
          <div style={data.linkColor} className="right__button">
            <a style={data.linkColor} href="#">View All</a>
          </div>
        </div>
    </div>
  )
}

export default WidgetsTotal