import axios from "axios";

export const fetchAllItems = async (url, allItems = [], page = 1) => {
    try {
        const response = await axios.get(`${url}?page=${page}`);
        const data = response.data.data;

        if (data.length > 0) {
            return fetchAllItems(url, [...allItems, ...data], page + 1);
        } else {
            return allItems;
        }
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};