import Sidebar from "../../Components/Sidebar/Sidebar"
import Navbar from "../../Components/Navbar/Navbar"
import "./home.scss"
import WidgetsUsers from "../../Components/Widgets/WidgetsUsers"
import WidgetsTotal from "../../Components/Widgets/WidgetsTotal"
import Widgets from "../../Components/Widgets/Widgets"
import Charts from "../../Components/Charts/Charts"
import Graf from "../../Components/Charts/Graf"
import PieCharts from "../../Components/PieCharts/PieCharts"

const Home = () => {
  return (
    <div className="home">
        <Sidebar/>
        <div className="home__container">
            <Navbar/>
            <div className="home__title">Dashbord</div>
            <div className="widgets">
                <WidgetsUsers type="total"/>
                <WidgetsUsers type="active"/>
                <WidgetsUsers type="email"/>
                <WidgetsUsers type="mobile"/>
            </div>
            <div className="widgets">
                <WidgetsTotal type="total"/>
                <WidgetsTotal type="active"/>
                <WidgetsTotal type="email"/>
                <WidgetsTotal type="mobile"/>
            </div>
            <div className="widgets">
                <Widgets type="total"/>
                <Widgets type="active"/>
                <Widgets type="email"/>
                <Widgets type="mobile"/>
            </div>
            <div className="charts">
                <Graf/>
                <Charts/>
            </div>
            <div className="pieCharts">
                <PieCharts/>
                <PieCharts/>
                <PieCharts/>
            </div>
        </div>
    </div>
  )
}

export default Home