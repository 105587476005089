import React from 'react'
import Navbar from '../../../Components/Navbar/Navbar'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import "./manageActive.scss"
import ActiveItems from "./ActiveItems";
function ManageActive() {
  return (
    <div className='manage-active'>
        <Sidebar/>
        
        <div className='manage-active__container'>
        <Navbar/>
            <div className='manage-active-block'>
                <ActiveItems type={'collection_items'} />
                <ActiveItems type={'nfts'} />
                <ActiveItems type={'collection'} />
            </div>
            
        </div>
        
    </div>
  )
}
export default ManageActive
